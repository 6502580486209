import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getMessageBody } from '../helpers/messages';
import { renderChatDate, renderChatTime } from '../helpers/date';
import useTwilio from '../hooks/useTwilio';
import useActiveSession from '../hooks/useActiveSession';
import withAuth from '../hooks/withAuth';
import Helmet from 'react-helmet';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationEmptyState from '../components/NotificationEmptyState';
import SkeletonLoader from '../components/SkeletonLoader';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    userSelect: 'none',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

function sortByDate(a, b) {
  return b.dateCreated - a.dateCreated;
}

const Notifications = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [messages, setMessages] = React.useState([]);
  const Twilio = useTwilio();

  useActiveSession(
    async session => {
      if (Twilio) {
        try {
          const channel = await Twilio.getChannel(
            session.user.notifaction_channel_id,
          );
          const result = await Twilio.getChannelMessages(channel);
          setMessages(result.items.sort(sortByDate));
        } catch (e) {}
        setLoading(false);
      }
    },
    [Twilio],
  );

  const onClick = url => {
    if (url) {
      window.location.href = url;
    }
  };

  if (!loading && !messages.length) {
    return (
      <NotificationEmptyState>
        <h3>ยังไม่มีการแจ้งเตือน</h3>
      </NotificationEmptyState>
    );
  }

  return (
    <>
      <Helmet>
        <title>การแจ้งเตือน</title>
      </Helmet>
      <List className={classes.root}>
        {loading && <SkeletonLoader />}
        {messages.map((message, index, array) => {
          let msg = getMessageBody(message);

          if (msg.type !== 'notification') {
            return <div key={index} />;
          }
          const url = msg.value.url;
          return (
            <div key={index}>
              <ListItem
                button
                alignItems='flex-start'
                onClick={() => onClick(url)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <NotificationsIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${msg.value.title} ${msg.value.message}`}
                  secondary={`${renderChatDate(
                    message.dateCreated,
                  )} ${renderChatTime(message.dateCreated)}`}
                />
              </ListItem>
              <Divider variant='inset' component='li' />
            </div>
          );
        })}
      </List>
    </>
  );
};

export default withAuth(Notifications);
